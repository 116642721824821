// @file Composable to encapsulate post duplicate and transfer logic

import { trackEvent } from '@@/bits/analytics'
import { isAppUsing } from '@@/bits/flip'
import { __ } from '@@/bits/intl'
import { navigateTo } from '@@/bits/location'
import { hasCustomProperties, postOnlyHasCustomProperties } from '@@/bits/post_properties'
import AlertSignSvg from '@@/images/alert_sign.svg'
import { useGlobalAlertDialogStore } from '@@/pinia/global_alert_dialog'
import { useGlobalConfirmationDialogStore } from '@@/pinia/global_confirmation_dialog'
import type { GlobalSnackbarNotification } from '@@/pinia/global_snackbar'
import { SnackbarNotificationType, useGlobalSnackbarStore } from '@@/pinia/global_snackbar'
import { useSurfacePostActionStore } from '@@/pinia/surface_post_action'
import { useSurfacePostsStore } from '@@/pinia/surface_posts'
import type { HashId, Wall } from '@@/types'
import { createSharedComposable } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'

const usePostDuplicateAndTransfer = createSharedComposable(
  (): {
    duplicatePost: typeof duplicatePost
    transferPost: typeof transferPost
    canPostBeCopiedOrTransferred: typeof canPostBeCopiedOrTransferred
    checkIfPostCanBeCopiedOrTransferred: typeof checkIfPostCanBeCopiedOrTransferred
    isCopyingPost: typeof isCopyingPost
    isTransferringPost: typeof isTransferringPost
  } => {
    const surfacePostsStore = useSurfacePostsStore()
    const surfacePostActionStore = useSurfacePostActionStore()
    const globalAlertDialogStore = useGlobalAlertDialogStore()
    const globalConfirmationDialogStore = useGlobalConfirmationDialogStore()
    const { copyOrTransferPostResult, xCopyPostPanel, xTransferPostPanel } = storeToRefs(surfacePostActionStore)

    const canPostBeCopiedOrTransferred = ref(false)
    const isTransferringPost = computed(() => canPostBeCopiedOrTransferred.value && xTransferPostPanel.value)
    const isCopyingPost = computed(() => canPostBeCopiedOrTransferred.value && xCopyPostPanel.value)
    const postUnderIntent = computed(() =>
      xCopyPostPanel.value ? surfacePostActionStore.postBeingCopied : surfacePostActionStore.postBeingTransferred,
    )

    const checkIfPostCanBeCopiedOrTransferred = (): void => {
      canPostBeCopiedOrTransferred.value = false

      if (postUnderIntent.value == null || (!xCopyPostPanel.value && !xTransferPostPanel.value)) {
        return
      }

      const currentPostAction = xCopyPostPanel.value ? 'copy' : 'transfer'
      if (postOnlyHasCustomProperties(postUnderIntent.value)) {
        const title =
          currentPostAction === 'copy'
            ? __('Posts with only custom fields cannot be duplicated to other padlets.')
            : __('Posts with only custom fields cannot be transferred to other padlets.')
        globalAlertDialogStore.openAlertDialog({
          iconSrc: AlertSignSvg,
          title,
          afterCloseActions: [
            () => {
              canPostBeCopiedOrTransferred.value = false
              currentPostAction === 'copy'
                ? surfacePostActionStore.endPostCopy()
                : surfacePostActionStore.endPostTransfer()
            },
          ],
        })
      } else if (hasCustomProperties(postUnderIntent.value)) {
        const title =
          currentPostAction === 'copy'
            ? __("This post's custom fields will not be duplicated.")
            : __("This post's custom fields will not be transferred.")
        globalConfirmationDialogStore.openConfirmationDialog({
          iconSrc: AlertSignSvg,
          title,
          afterConfirmActions: [
            () => {
              canPostBeCopiedOrTransferred.value = true
            },
          ],
          afterCancelActions: [
            () => {
              canPostBeCopiedOrTransferred.value = false
              currentPostAction === 'copy'
                ? surfacePostActionStore.endPostCopy()
                : surfacePostActionStore.endPostTransfer()
            },
          ],
        })
      } else {
        canPostBeCopiedOrTransferred.value = true
      }
    }

    const duplicatePost = (wall: Wall, wallSectionHashid?: HashId): void => {
      if (surfacePostActionStore.postBeingCopiedCid == null) return
      const post = surfacePostsStore.postEntitiesByCid[surfacePostActionStore.postBeingCopiedCid]
      if (post == null || post.hashid == null || post.id == null) return

      const isDestinationThisWall = post.wall_hashid === wall.hashid
      if (isDestinationThisWall) {
        trackEvent('Posts', 'Copied post to the same or an existing padlet')
      } else {
        trackEvent('Posts', 'Copied post to a new padlet')
      }

      surfacePostActionStore.actuallyCopyPost({
        wallHashid: wall.hashid,
        wallSectionHashid,
        isDestinationThisWall,
        postId: post.id,
        postHashid: post.hashid,
        transferredPostCid: surfacePostActionStore.postBeingCopiedCid,
      })

      surfacePostActionStore.endPostCopy()
    }

    const transferPost = (wall: Wall, wallSectionHashid?: HashId): void => {
      if (surfacePostActionStore.postBeingTransferredCid == null) return
      const post = surfacePostsStore.postEntitiesByCid[surfacePostActionStore.postBeingTransferredCid]
      if (post == null || post.hashid == null || post.id == null) return

      const isDestinationThisWall = post.wall_hashid === wall.hashid
      if (isDestinationThisWall) {
        // cannot transfer post to the same wall
        return
      } else {
        trackEvent('Posts', 'Transferred post to a new padlet')
      }
      surfacePostActionStore.actuallyTransferPost({
        wallHashid: wall.hashid,
        isDestinationThisWall,
        postId: post.id,
        postHashid: post.hashid,
        wallSectionHashid,
        transferredPostCid: surfacePostActionStore.postBeingTransferredCid,
      })
      surfacePostActionStore.endPostTransfer()
    }

    if (isAppUsing('padletPickerV2')) {
      watch(copyOrTransferPostResult, (newResult: any) => {
        if (newResult == null) return
        let notification: GlobalSnackbarNotification = { message: newResult.text }

        if (newResult.status === 'error') {
          notification.notificationType = SnackbarNotificationType.error
        } else {
          if (newResult.isDestinationThisWall !== true) {
            notification = {
              ...notification,
              actionTextActions: [() => navigateTo(newResult.url)],
              actionText: __('Go to padlet'),
            }
          }
        }

        useGlobalSnackbarStore().setSnackbar(notification)
      })

      watch(
        [xCopyPostPanel, xTransferPostPanel, postUnderIntent],
        () => {
          checkIfPostCanBeCopiedOrTransferred()
        },
        { immediate: true },
      )
    }

    return {
      duplicatePost,
      transferPost,

      canPostBeCopiedOrTransferred,
      checkIfPostCanBeCopiedOrTransferred,

      isCopyingPost,
      isTransferringPost,
    }
  },
)

export default usePostDuplicateAndTransfer
